body header {
  width: 100%;
  background-color: #000000;
  z-index: 999;
}
body header .desktop-header .header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 14px 0;
}
body header .desktop-header .header .left-side .logo {
  display: inline-block;
}
body header .desktop-header .header .left-side .logo img {
  width: auto;
  object-fit: contain;
  height: 42px;
}
body header .desktop-header .header .right-side {
  display: flex;
}
body header .desktop-header .header .right-side form {
  display: flex;
  grid-gap: 10px;
}
body header .desktop-header .header .right-side form input {
  width: 130px;
}
body header .desktop-header .header .right-side form button {
  white-space: nowrap;
  text-transform: uppercase;
}
body header .desktop-header .header .right-side .account-block {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  justify-content: flex-end;
  margin: 0 0 6px 20px;
}
body header .desktop-header .header .right-side .account-block p {
  display: flex;
}
body header .desktop-header .header .right-side .account-block p b {
  display: block;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 250px;
}
body header .desktop-header .header .right-side .sign-in-timer {
  display: flex;
  justify-content: flex-end;
  color: #ffffff;
  flex-basis: 100%;
  margin-bottom: 6px;
  font-size: calc(10px + var(--big-fs));
  line-height: 14px;
}
body header .mobile-header .header {
  display: flex;
  align-items: center;
  background-repeat: no-repeat;
  height: 50px;
  background-color: #000000;
  grid-gap: 24px;
}
body header .mobile-header .header > a {
  display: flex;
  color: #ffffff;
}
body header .mobile-header .header .icon-btn {
  color: #ffffff;
  font-size: calc(24px + var(--big-fs));
  line-height: 1;
}
body header .mobile-header .header .link-black,
body header .mobile-header .header .text-black {
  color: #ffffff;
}
body header .mobile-header .header .logo-sm {
  margin-right: auto;
  z-index: 11;
}
body header .mobile-header .header .language-change {
  display: none;
  z-index: 11;
}
body header .mobile-header .header .hamburger-menu {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 20px;
  width: 20px;
  z-index: 11;
  cursor: pointer;
}
body header .mobile-header .header .hamburger-menu .line {
  display: block;
  width: 20px;
  height: 2px;
  border-radius: 2px;
  background: #ffffff;
}
body header .mobile-header .header .hamburger-menu .line.line-1 {
  transform-origin: 0 0;
  transition: transform 0.4s ease-in-out;
}
body header .mobile-header .header .hamburger-menu .line.line-2 {
  transition: transform 0.2s ease-in-out;
}
body header .mobile-header .header .hamburger-menu .line.line-3 {
  transform-origin: 0 100%;
  transition: transform 0.4s ease-in-out;
}
body header .mobile-header .header .sidebar {
  display: flex;
  flex-direction: column;
  background: #000000;
  width: 250px;
  height: 100%;
  position: fixed;
  right: 0;
  top: 0;
  padding-top: 70px;
  transform: translateX(100%);
  transition: transform 0.35s ease;
  z-index: 10;
}
body header .mobile-header .header .sidebar,
body header .mobile-header .header .sidebar button {
  font-size: calc(12px + var(--big-fs));
  line-height: 16px;
}
body header .mobile-header .header .sidebar .sidebar-body {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  overflow: auto;
  height: inherit;
}
body header .mobile-header .header .sidebar .sidebar-body ul {
  width: 100%;
  margin-bottom: 70px;
  padding-left: 0;
}
body header .mobile-header .header .sidebar .sidebar-body ul li {
  display: flex;
  align-items: center;
}
body header .mobile-header .header .sidebar .sidebar-body ul li a {
  display: flex;
  align-items: center;
  padding: 10px 20px;
  font-size: 16px;
  line-height: 19px;
  font-weight: bold;
  width: 100%;
}
body header .mobile-header .header .sidebar .sidebar-body ul li a i {
  margin-right: 20px;
  font-size: calc(24px + var(--big-fs));
  line-height: 1;
}
@media (max-width: 1024px) {
  body header .mobile-header .header .sidebar {
    width: 100%;
  }
}
@media (min-width: 1025px) {
  body header .mobile-header {
    display: none;
  }
}
@media (max-width: 1024px) {
  body header {
    position: sticky;
    top: 0;
  }
  body header .desktop-header {
    display: none;
  }
}
body .language-change > button {
  width: 46px;
  height: 22px;
  border-radius: 10.5px;
}
body .language-change button {
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: #222221;
  border: 1px solid transparent;
  text-transform: uppercase;
}
body .language-change button img {
  width: 20px;
  height: 20px;
}
body .language-change button span {
  color: #ffffff;
  font-weight: 600;
  padding: 2px 2px 2px 5px;
  font-size: calc(11px + var(--big-fs));
  line-height: 14px;
}
body .language-change .dropdown-menu {
  grid-gap: 6px;
  border-radius: 10.5px;
  overflow: hidden;
  min-width: unset;
  background-color: #222221;
  top: -1px !important;
}
body .language-change .dropdown-menu button {
  width: 100px;
  padding: 3px 6px;
}
body .language-change .dropdown-menu button:hover {
  background-color: #565653;
}
body.sidebar-open header {
  z-index: 9999;
}
body.sidebar-open header .mobile-header .header .sidebar {
  transform: translateX(0px);
}
body.sidebar-open header .mobile-header .header .sidebar-backdrop {
  display: block;
}
body.sidebar-open header .mobile-header .header .language-change {
  display: block;
  margin-right: 6px;
}
body.sidebar-open header .mobile-header .header .language-change button {
  background-color: #222221;
}
body.sidebar-open header .mobile-header .header .language-change button img {
  width: 32px;
  height: 32px;
}
body.sidebar-open header .mobile-header .header .language-change button span {
  color: #ffffff;
  padding: 2px 4px 2px 10px;
  font-size: calc(14px + var(--big-fs));
  line-height: 18px;
}
body.sidebar-open header .mobile-header .header .language-change .language-toggle {
  height: 34px;
  width: 71px;
  border: 1px solid #222221;
  border-radius: 17px;
}
body.sidebar-open header .mobile-header .header .language-change .dropdown-menu {
  border: 1px solid #222221;
  background-color: #222221;
  border-radius: 17px;
  padding: 0;
}
body.sidebar-open header .mobile-header .header .language-change .dropdown-menu button {
  padding: 6px;
}
body.sidebar-open header .mobile-header .header .hamburger-menu .line {
  width: 26px;
}
body.sidebar-open header .mobile-header .header .hamburger-menu .line.line-1 {
  transform: rotate(45deg);
}
body.sidebar-open header .mobile-header .header .hamburger-menu .line.line-2 {
  transform: scaleY(0);
}
body.sidebar-open header .mobile-header .header .hamburger-menu .line.line-3 {
  transform: rotate(-45deg);
}
body.sidebar-open header .icon-btn {
  display: none !important;
}
body.overflow-hidden, body.splash-hidden {
  overflow: hidden;
}

body nav {
  width: 100%;
  background-color: #d61f79;
}
body nav .sc-container {
  display: flex;
}
body nav .sc-container a:not(.dropdown-item) {
  padding: 6px 0;
}
body nav .sc-container a:not(.dropdown-item):not(:last-child) {
  margin-right: 24px;
}
body nav .sc-container a:not(.dropdown-item):hover {
  text-decoration: underline;
}
body nav .sc-container .right-side {
  display: flex;
  align-items: center;
  grid-gap: 10px;
  margin-left: auto;
}
body nav .sc-container .right-side .input-group {
  width: 90px;
}
body nav .sc-container .right-side .input-group img {
  filter: contrast(0.4);
}

body {
  display: flex;
  flex-direction: column;
  background-color: #ffffff;
  min-height: 100vh;
  overflow-x: hidden;
}
body .sc-container-fluid {
  display: grid;
  grid-template-columns: 960px 206px;
  margin: 0 auto;
  grid-column-gap: 10px;
  padding: 0 16px;
  width: fit-content;
  position: relative;
}
body .left-side-banner {
  position: absolute;
  left: -300px;
  height: 100%;
}
body .left-side-banner .sc-banners-list {
  position: sticky;
  top: 20px;
  left: 0;
  width: 300px;
  height: 600px;
  margin-top: 20px;
}
body .left-side-banner .sc-banners-list .ixsHitBtn,
body .left-side-banner .sc-banners-list .ixsHitBtn img {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 4px;
}
@media (max-width: 1829px) {
  body .left-side-banner {
    display: none;
  }
}
body .breadcrumb-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 12px 0;
  border-bottom: 1px solid #f0f0f0;
  min-height: 40px;
}
body .breadcrumb-container .breadcrumb {
  display: flex;
  flex-wrap: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
body .breadcrumb-container .breadcrumb li {
  position: relative;
  display: block;
  align-items: center;
  font-weight: bold;
  white-space: nowrap;
  font-size: calc(12px + var(--big-fs));
  line-height: 16px;
}
body .breadcrumb-container .breadcrumb li:last-child {
  overflow: hidden;
  text-overflow: ellipsis;
}
body .breadcrumb-container #next-prev {
  display: flex;
  grid-gap: 30px;
}
body .breadcrumb-container #next-prev a {
  display: flex;
  align-items: center;
  text-transform: uppercase;
}
body .breadcrumb-container #next-prev a i {
  font-size: calc(16px + var(--big-fs));
  line-height: 22px;
}
@media (min-width: 1025px) {
  body .breadcrumb-container.hide-breadcrumb {
    display: none;
  }
}
@media (max-width: 768px) {
  body .breadcrumb-container {
    padding: 13px 16px;
    margin-left: -16px;
    margin-right: -16px;
    min-height: 56px;
  }
  body .breadcrumb-container .breadcrumb:not(.p-0),
  body .breadcrumb-container .breadcrumb.next-prev {
    padding: 20px 16px !important;
  }
  body .breadcrumb-container #next-prev {
    padding: 16px !important;
  }
}
body main {
  flex: 1;
}
@media (max-width: 1207px) {
  body .sc-container-fluid {
    grid-template-columns: minmax(0, 960px);
  }
  body main .aside aside {
    display: none;
  }
}

body main aside {
  display: grid;
  grid-gap: 20px;
  margin-top: 20px;
  position: sticky;
  top: 20px;
}
body main aside .title {
  display: grid;
  align-items: center;
  grid-template-columns: auto 1fr;
  grid-gap: 10px;
  margin-bottom: 12px;
  color: #000000;
  font-weight: bold;
  font-size: calc(12px + var(--big-fs));
  line-height: 16px;
}
body main aside .title .line {
  display: block;
  width: 100%;
  height: 2px;
  background-color: #f0f0f0;
  flex-grow: 0;
}
body main aside #available-for-chat .content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  grid-gap: 12px;
  padding: 20px 16px;
  border-radius: 4px;
  background-color: #f0f0f0;
}
body main aside #available-for-chat .content .escort-item {
  display: grid;
  grid-template-columns: auto 1fr;
  grid-gap: 10px;
  align-items: center;
}
body main aside #available-for-chat .content .escort-item .avatar img {
  border-radius: 50%;
  object-fit: cover;
}
body main aside #available-for-chat .content .escort-item .info {
  display: grid;
  grid-template-columns: 1fr auto;
  align-items: center;
  grid-gap: 4px;
  height: fit-content;
}
body main aside #available-for-chat .content .escort-item .info .online {
  display: block;
  width: 10px;
  height: 10px;
  background-color: #02b10e;
  border-radius: 50%;
}
body main aside #available-for-chat .content .btn {
  min-width: 130px;
  max-width: 100%;
  width: fit-content;
  margin: 0 auto;
}
body main aside #happy-hour-live .content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  grid-gap: 12px;
  padding: 20px 16px;
  border-radius: 4px;
  background-color: #f0f0f0;
}
body main aside #happy-hour-live .content .escort-item {
  display: grid;
  grid-template-columns: auto 1fr;
  grid-gap: 10px;
  align-items: center;
}
body main aside #happy-hour-live .content .escort-item .avatar img {
  border-radius: 2px;
  object-fit: cover;
}
body main aside #happy-hour-live .content .escort-item .info {
  display: grid;
  grid-template-columns: 1fr;
  align-items: center;
  grid-gap: 2px;
  height: fit-content;
}
body main aside #happy-hour-live .content .escort-item .info .online {
  display: block;
  width: 10px;
  height: 10px;
  background-color: #02b10e;
  border-radius: 50%;
}
body main aside #happy-hour-live .content .btn {
  min-width: 130px;
  max-width: 100%;
  width: fit-content;
  margin: 0 auto;
}
body main aside #latest-status-messages .content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  grid-gap: 10px;
}
body main aside #latest-status-messages .content .status-message {
  display: grid;
  grid-template-columns: 60px 1fr;
  border-radius: 4px;
  background: #d61f79;
  color: #ffffff;
}
body main aside #latest-status-messages .content .status-message .left-side {
  overflow: hidden;
  border-radius: 4px;
}
body main aside #latest-status-messages .content .status-message .left-side img {
  object-fit: cover;
}
body main aside #latest-status-messages .content .status-message .right-side {
  padding: 4px 6px;
  width: 100%;
}
body main aside #latest-status-messages .content .status-message .right-side .header {
  display: flex;
  justify-content: space-between;
  margin-bottom: 2px;
}
body main aside #happy-hour-live:not(.open),
body main aside #available-for-chat:not(.open),
body main aside #latest-status-messages:not(.open) {
  display: none;
}
body main #latest-status-messages-sm {
  position: fixed;
  width: auto;
  bottom: 10px;
  left: 10px;
  right: 10px;
  padding: 5px 8px;
  border-radius: 4px;
  background-color: #d61f79;
  color: #ffffff;
  display: grid;
  grid-template-columns: auto 1fr auto;
  grid-gap: 8px;
  align-items: center;
  z-index: 101;
  font-size: calc(14px + var(--big-fs));
  line-height: 18px;
}
body main #latest-status-messages-sm #latestStatusMessagesCarousel {
  padding: 0;
}
body main #latest-status-messages-sm .sc {
  font-size: calc(24px + var(--big-fs));
  line-height: 1;
}
body main #latest-status-messages-sm .carousel-inner {
  margin: 0 -6px;
  width: auto;
}
body main #latest-status-messages-sm .carousel-inner .carousel-item {
  transition-duration: 0.4s;
}
body main #latest-status-messages-sm .c-container {
  display: grid;
  align-items: center;
  grid-template-columns: auto 1fr;
  grid-gap: 10px;
  padding: 0 6px;
}
body main #latest-status-messages-sm .c-container .image {
  width: 75px;
  height: 75px;
  border-radius: 4px;
  overflow: hidden;
}
body main #latest-status-messages-sm .c-container .image img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
body main #latest-status-messages-sm .c-container .content {
  display: grid;
  grid-gap: 3px;
}
body main #latest-status-messages-sm .c-container .content div {
  display: flex;
  justify-content: space-between;
  align-items: center;
  grid-gap: 10px;
}
body main #latest-status-messages-sm .carousel-close,
body main #latest-status-messages-sm .status-alert {
  position: absolute;
  top: -10px;
  border-radius: 50%;
  background-color: #000000;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 24px;
  height: 24px;
}
body main #latest-status-messages-sm .carousel-close .sc,
body main #latest-status-messages-sm .status-alert .sc {
  font-size: calc(16px + var(--big-fs));
  line-height: 1;
}
body main #latest-status-messages-sm .carousel-close {
  right: 0;
}
body main #latest-status-messages-sm .status-alert {
  left: 0;
}

body footer {
  width: 100%;
  background-color: #000000;
  color: #ffffff;
}
body footer .sc-container {
  display: grid;
  grid-gap: 31px;
  padding: 20px 0 25px;
}
body footer .sc-container .top {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
body footer .sc-container .top .logo {
  margin-right: 30px;
}
body footer .sc-container .top .logo img {
  width: 118px;
  height: 42px;
  object-fit: contain;
}
body footer .sc-container .top .footer-nav {
  display: flex;
  flex-wrap: wrap;
  grid-gap: 16px;
  margin-top: 20px;
  font-size: calc(11px + var(--big-fs));
  line-height: 14px;
}
body footer .sc-container .bottom {
  display: grid;
  grid-gap: 16px;
}
body footer .sc-container .bottom p {
  font-size: calc(10px + var(--big-fs));
  line-height: 14px;
}
@media (max-width: 1024px) {
  body footer .sc-container {
    padding-bottom: 30px;
  }
  body footer .sc-container .top {
    flex-direction: column;
    align-items: flex-start;
  }
  body footer .sc-container .top .logo img {
    width: 100px;
    height: 24px;
  }
  body footer .sc-container .top .footer-nav {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(140px, 1fr));
    width: 100%;
    margin-top: 30px;
    margin-bottom: 10px;
  }
  body footer .sc-container a {
    font-size: calc(14px + var(--big-fs));
    line-height: 18px;
  }
  body footer .sc-container .bottom {
    grid-gap: 30px;
  }
  body footer .sc-container .bottom p {
    font-size: calc(12px + var(--big-fs));
    line-height: 16px;
  }
}

.prices-page-container {
  padding: 0 !important;
  font-weight: normal;
  font-size: calc(14px + var(--big-fs));
  line-height: 18px;
}
.prices-page-container .header-info br {
  display: block;
}
.prices-page-container .banner-prices {
  display: grid;
}
.prices-page-container .banner-prices header {
  all: unset;
  background-color: transparent;
}
.prices-page-container .banner-prices header .header-title {
  padding-top: 16px;
  padding-bottom: 16px;
  margin-bottom: 20px;
  border-bottom: 1px solid #f0f0f0;
  font-weight: bold;
  display: flex;
  grid-gap: 20px;
  position: relative;
  font-size: calc(18px + var(--big-fs));
  line-height: 24px;
}
.prices-page-container .banner-prices header .header-title svg {
  display: none;
}
.prices-page-container .banner-prices header .header-title:before {
  content: "\e935";
  font-family: "and6";
  color: #000000;
  position: relative;
  left: 0;
}
.prices-page-container .banner-prices header .header-info {
  display: grid;
  grid-gap: 10px;
}
.prices-page-container .banner-prices header .header-info br {
  display: none;
}
.prices-page-container .banner-prices .content-wrapper {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  margin-top: 40px;
  grid-gap: 30px 20px;
}
.prices-page-container .banner-prices .content-wrapper .banner-item {
  display: flex;
  flex-direction: column;
}
.prices-page-container .banner-prices .content-wrapper .banner-item .banner-title {
  display: flex;
  justify-content: center;
  font-weight: bold;
  margin-bottom: 10px;
  font-size: calc(16px + var(--big-fs));
  line-height: 22px;
}
.prices-page-container .banner-prices .content-wrapper .banner-item .banner-wrapper {
  display: grid;
  background-color: #f0f0f0;
  border-radius: 8px;
  padding: 20px;
  height: 100%;
  grid-template-rows: auto 1fr;
}
.prices-page-container .banner-prices .content-wrapper .banner-item .banner-wrapper .coming-soon {
  text-align: center;
  font-size: calc(16px + var(--big-fs));
  line-height: 22px;
}
.prices-page-container .banner-prices .content-wrapper .banner-item .banner-wrapper .banner-image-section .banner-image-nav {
  display: flex;
  justify-content: center;
  border-radius: 8px;
  width: fit-content;
  margin: 0 auto 10px;
  overflow: hidden;
  position: relative;
}
.prices-page-container .banner-prices .content-wrapper .banner-item .banner-wrapper .banner-image-section .banner-image-nav span {
  padding: 8px 14px;
  text-transform: uppercase;
  color: #d61f79;
  cursor: pointer;
  font-size: calc(11px + var(--big-fs));
  line-height: 14px;
}
.prices-page-container .banner-prices .content-wrapper .banner-item .banner-wrapper .banner-image-section .banner-image-nav span.disabled {
  opacity: 0.5;
  pointer-events: none;
}
.prices-page-container .banner-prices .content-wrapper .banner-item .banner-wrapper .banner-image-section .banner-image-nav span.active {
  color: #ffffff;
  background-color: #d61f79;
  pointer-events: none;
}
.prices-page-container .banner-prices .content-wrapper .banner-item .banner-wrapper .banner-image-section .banner-image-nav:before {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  border: 1px solid #d61f79;
  border-radius: 8px;
  z-index: 1;
  pointer-events: none;
}
.prices-page-container .banner-prices .content-wrapper .banner-item .banner-wrapper .banner-image-section .banner-image-nav.mobile-disabled:before {
  border-color: rgba(214, 31, 121, 0.5);
}
.prices-page-container .banner-prices .content-wrapper .banner-item .banner-wrapper .banner-image-section .banner-image {
  width: 340px;
  height: 250px;
  border: 1px solid #d4d4d4;
  padding: 4px;
  background-color: #ffffff;
  border-radius: 8px;
}
.prices-page-container .banner-prices .content-wrapper .banner-item .banner-wrapper .banner-image-section .banner-image img {
  display: none;
  width: 100%;
  height: 100%;
  object-fit: contain;
  pointer-events: none;
}
.prices-page-container .banner-prices .content-wrapper .banner-item .banner-wrapper .banner-image-section .banner-image img.active {
  display: block;
}
.prices-page-container .banner-prices .content-wrapper .banner-item .banner-wrapper .banner-btn,
.prices-page-container .banner-prices .content-wrapper .banner-item .banner-wrapper .banner-mobile-btn {
  display: block;
  grid-column-start: 2;
  width: 100%;
}
.prices-page-container .banner-prices .content-wrapper .banner-item .banner-wrapper .banner-btn a,
.prices-page-container .banner-prices .content-wrapper .banner-item .banner-wrapper .banner-mobile-btn a {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 34px;
  padding: 4px 10px;
  background-color: #d61f79;
  color: #ffffff;
  border-radius: 8px;
  margin-top: 20px;
  text-decoration: none;
}
.prices-page-container .banner-prices .content-wrapper .banner-item .banner-info-section p {
  color: #000000;
  font-weight: bold;
  margin-bottom: 10px;
  font-size: calc(14px + var(--big-fs));
  line-height: 18px;
}
.prices-page-container .banner-prices .content-wrapper .banner-item .banner-info-section .banner-info-text {
  display: grid;
  grid-gap: 2px;
}
.prices-page-container .banner-prices .content-wrapper .banner-item .banner-info-section .banner-info-text > div {
  display: flex;
  justify-content: space-between;
  grid-gap: 10px;
  font-weight: 400;
  color: #000000;
}
.prices-page-container .banner-prices .content-wrapper .banner-item .banner-info-section .banner-info-text > div > div:first-child {
  font-weight: 600;
}
.prices-page-container .banner-prices .content-wrapper .banner-item .banner-info-section .banner-info-text > div > div:first-child span {
  font-weight: 400;
}
.prices-page-container .banner-prices .content-wrapper .banner-item .banner-info-section .banner-info-text:not(:last-child) {
  margin-bottom: 20px;
}
.prices-page-container .banner-prices .content-wrapper .banner-item.sedcard-item {
  grid-column: 1/-1;
}
.prices-page-container .banner-prices .content-wrapper .banner-item.sedcard-item .banner-image-section .banner-image-nav {
  margin: 0 auto 38px;
}
.prices-page-container .banner-prices .content-wrapper .banner-item.sedcard-item .banner-wrapper {
  justify-content: space-around;
  grid-template-columns: repeat(2, 420px);
}
.prices-page-container .banner-prices .content-wrapper .banner-item.sedcard-item .banner-info-section {
  display: flex;
  flex-direction: column;
}
.prices-page-container .banner-prices .content-wrapper .banner-item.sedcard-item .banner-info-section,
.prices-page-container .banner-prices .content-wrapper .banner-item.sedcard-item .banner-btn {
  max-width: 420px;
  margin: 0 auto;
}
.prices-page-container .banner-prices .content-wrapper .banner-item.sedcard-item .banner-info-section .banner-info-text > div > div:first-child span.text-normal,
.prices-page-container .banner-prices .content-wrapper .banner-item.sedcard-item .banner-btn .banner-info-text > div > div:first-child span.text-normal {
  font-style: italic;
}
.prices-page-container .banner-prices .content-wrapper .banner-item.sedcard-item .banner-info-section .banner-info-text > div > div:last-child,
.prices-page-container .banner-prices .content-wrapper .banner-item.sedcard-item .banner-btn .banner-info-text > div > div:last-child {
  white-space: nowrap;
}
.prices-page-container .banner-prices .content-wrapper .banner-item.sedcard-item .banner-btn {
  display: none;
}
.prices-page-container .banner-prices .content-wrapper .banner-item.sedcard-item .banner-mobile-btn {
  display: block;
  margin-top: auto;
}
.prices-page-container .banner-prices .content-wrapper .banner-item:not(.sedcard-item) .banner-wrapper .banner-image-section,
.prices-page-container .banner-prices .content-wrapper .banner-item:not(.sedcard-item) .banner-wrapper .banner-info-section {
  width: 340px;
  margin: 0 auto;
}
.prices-page-container .banner-prices .content-wrapper .banner-item:not(.sedcard-item) .banner-info-section .banner-info-text {
  padding-top: 20px;
}
.prices-page-container .banner-prices .content-wrapper .banner-item:not(.sedcard-item) .banner-info-section .banner-info-text > div:first-child {
  margin-bottom: 0 !important;
}
.prices-page-container .banner-prices .content-wrapper .banner-item:not(.sedcard-item) .banner-info-section .banner-info-text > div:last-child {
  margin-top: 10px;
}
.prices-page-container .banner-prices .content-wrapper .banner-item:not(.sedcard-item) .banner-info-section .banner-info-text > div:last-child > div:first-child {
  display: none;
}
@media screen and (max-width: 900px) {
  .prices-page-container .banner-prices header .header-title {
    padding: 20px 0;
    margin-bottom: 0;
  }
  .prices-page-container .banner-prices header .header-info {
    margin-top: 30px;
  }
  .prices-page-container .banner-prices .content-wrapper {
    grid-template-columns: 1fr;
  }
  .prices-page-container .banner-prices .content-wrapper .banner-item .banner-image {
    max-width: 350px;
    width: 100% !important;
    margin: 0 auto;
  }
  .prices-page-container .banner-prices .content-wrapper .banner-item .banner-wrapper {
    margin-left: -16px;
    margin-right: -16px;
    border-radius: 0;
    padding: 20px 16px;
  }
  .prices-page-container .banner-prices .content-wrapper .banner-item .banner-wrapper .banner-image-nav {
    margin-bottom: 10px !important;
  }
  .prices-page-container .banner-prices .content-wrapper .banner-item.sedcard-item .banner-wrapper {
    grid-template-columns: 1fr;
  }
  .prices-page-container .banner-prices .content-wrapper .banner-item.sedcard-item .banner-wrapper .banner-image {
    margin: 0 auto;
  }
  .prices-page-container .banner-prices .content-wrapper .banner-item.sedcard-item .banner-wrapper .banner-info-section {
    margin-top: 20px;
  }
  .prices-page-container .banner-prices .content-wrapper .banner-item.sedcard-item .banner-wrapper .banner-mobile-btn {
    grid-column-start: unset;
  }
  .prices-page-container .banner-prices .content-wrapper .banner-item:not(.sedcard-item) .banner-wrapper .banner-image-section,
  .prices-page-container .banner-prices .content-wrapper .banner-item:not(.sedcard-item) .banner-wrapper .banner-info-section {
    max-width: 340px;
    width: 100% !important;
    margin: 0 auto;
  }
}
.prices-page-container.member .sedcard-item .banner-btn,
.prices-page-container.member .gotd-item .banner-btn,
.prices-page-container.member .votd-item .banner-btn {
  display: none !important;
}
@media (max-width: 768px) {
  .prices-page-container header .header-title {
    margin-left: -16px;
    margin-right: -16px;
    padding: 20px 16px !important;
  }
}

.static-content {
  font-weight: normal;
  font-size: calc(14px + var(--big-fs));
  line-height: 18px;
}
.static-content h1 {
  padding-top: 16px;
  padding-bottom: 16px;
  margin-bottom: 20px;
  border-bottom: 1px solid #f0f0f0;
  font-weight: bold;
  font-size: calc(18px + var(--big-fs));
  line-height: 24px;
}
.static-content h2 {
  font-weight: bold;
}
.static-content a {
  text-decoration: underline;
  color: #000000;
}
.static-content a:hover {
  color: black;
}
.static-content a:active {
  color: black;
}
.static-content li {
  padding: 10px 0;
}
.static-content h2,
.static-content p,
.static-content strong,
.static-content li {
  padding-left: 0 !important;
  font-size: calc(14px + var(--big-fs));
  line-height: 18px;
}
@media (min-width: 480px) {
  .static-content,
  .static-content h1 {
    padding-left: 30px !important;
    padding-right: 30px !important;
  }
  .static-content h1 {
    margin-left: -30px;
    margin-right: -30px;
  }
}
@media (max-width: 479px) {
  .static-content h1 {
    padding: 20px 0 30px;
    border-bottom: none;
    margin-bottom: 0;
  }
}
